let menuContent = document.getElementById('menu');
function menu() {
  var hamburger = document.querySelector(".hamburger");
  hamburger.addEventListener("click", function() {
    let menuOn = hamburger.classList.toggle("is-active");
    if(menuOn) {
      menuContent.style.display = "flex";
      menuContent.style.animationName ="menuOn";
    } else {
      menuContent.style.animationName ="menuOff";
      setTimeout(() => {
        menuContent.style.display = "none";
      }, 500);
    }
  });
}

menu();